<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="12" md="1" lg="2">
        </b-col>
        <b-col md="10" lg="8">

          <div>
            <span class="float-right" v-if="isAuthenticated">
              <b-link @click="onLogout">Log Out</b-link>
            </span>
            <span class="float-right" v-if="!isAuthenticated && !showLogin">
              <b-link @click="showLogin = true">Log In</b-link>
            </span>
            <h2>My Donor Dashboard</h2>
          </div>
          <hr />

          <div v-if="!showLogin">
            <h3>Schedule a New Pickup</h3>
            <p>Enter your zip code below to schedule a new pickup.</p>

            <b-form @submit="onNewSchedule" inline>
              <label class="sr-only" for="zipCode">Zip Code</label>
              <b-form-input id="zipCode" v-model="zipCode" type="number" />

              <div class="text-center">
                <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Schedule a New Pickup</b-button>
              </div>
            </b-form>
            <hr />

            <div v-if="futureSchedules">
              <h3>Upcoming Pickups</h3>
              <div v-if="futureSchedules && futureSchedules.length > 0">
                <b-table-simple responsive bordered>
                  <b-tr v-for="schedule in futureSchedules" v-bind:key="schedule.id">
                    <b-td>{{getFormattedDate(schedule.pickupDate)}}</b-td>
                    <b-td>{{getFormattedAddress(schedule)}}</b-td>
                    <b-td v-if="schedule.type == 'Default' && schedule.cancellationReason != 'Active'">
                      Cancelled/Rescheduled
                    </b-td>
                    <b-td v-if="schedule.type == 'Default' && schedule.cancellationReason == 'Active'">
                      <b-button-group>
                        <b-button :to="getChangeUrl(schedule)" v-if="schedule.scheduleCutoff.state != 'Closed'">Change</b-button>
                        <b-button :to="getRescheduleUrl(schedule)" v-if="schedule.scheduleCutoff.state != 'Closed'">Reschedule</b-button>
                        <b-button :to="getCancelUrl(schedule)" v-if="schedule.scheduleCutoff.state != 'Closed'">Cancel</b-button>
                      </b-button-group>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </div>
              <div v-if="!futureSchedules || futureSchedules.length == 0">
                <p>You do not have any upcoming pickups scheduled.</p>
              </div>
            </div>
            <hr />

            <div v-if="pastSchedulesRows">
              <h3>Past Pickups</h3>
              <div v-if="pastSchedulesRows && pastSchedulesRows.length > 0">
                <b-table-simple responsive bordered>
                  <b-tr v-for="(scheduleRow, index) in pastSchedulesRows" v-bind:key="index">
                    <b-td v-if="scheduleRow.type == 'data'">{{getFormattedDate(scheduleRow.schedule.pickupDate)}}</b-td>
                    <b-td v-if="scheduleRow.type == 'data'">{{getFormattedAddress(scheduleRow.schedule)}}</b-td>
                    <b-td v-if="scheduleRow.type == 'data' && scheduleRow.schedule.cancellationReason != 'Active'">
                      Cancelled/Rescheduled
                    </b-td>
                    <b-td v-if="scheduleRow.type == 'data' && scheduleRow.schedule.cancellationReason == 'Active'">
                      <b-button v-if="scheduleRow.schedule.emailAddress" @click="toggleTaxReceiptPrep(scheduleRow.schedule.id)">Email Tax Receipt</b-button>
                    </b-td>
                    <b-td v-if="scheduleRow.type == 'taxReceiptPrep' && taxReceiptPrepScheduleIds.includes(scheduleRow.schedule.id)" colspan="3">
                      Would you like us to email a tax receipt for this pickup to {{scheduleRow.schedule.emailAddress}}?
                      <b-button-group>
                        <b-button @click="emailTaxReceipt(scheduleRow.schedule.id)" variant="primary">Yes</b-button>
                        <b-button @click="toggleTaxReceiptPrep(scheduleRow.schedule.id)">No</b-button>
                      </b-button-group>
                    </b-td>
                    <b-td v-if="scheduleRow.type == 'taxReceiptSent' && taxReceiptSentScheduleIds.includes(scheduleRow.schedule.id)" colspan="3">
                      A tax receipt for this pickup has been emailed to {{scheduleRow.schedule.emailAddress}}.
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </div>
              <div v-if="!pastSchedules || pastSchedules.length == 0">
                <p>You do not have any past pickups.</p>
              </div>
            </div>

          </div>

          <div v-if="showLogin">
            <p>Please log in below.</p>

            <p><b-link @click="showLogin = false">Don't have an account?</b-link></p>

            <b-form @submit="onSubmitLogin">
              <b-card>
                <login-control ref="loginControl" :login-data="loginData"></login-control>
              </b-card>

              <div class="text-center">
                <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Log In</b-button>
              </div>
            </b-form>

          </div>

        </b-col>
        <b-col sm="12" md="1" lg="2">
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import Constants from '../../store/constants'
import axios from 'axios'
import FormattingMixin from '../mixins/FormattingMixin.vue';
import { saveAs } from 'file-saver';
import DataLayerMixin from '../mixins/DataLayerMixin.vue';

export default {
  name: 'Dashboard',
  mixins: [
    FormattingMixin,
    DataLayerMixin
  ],
  props: {
  },
  data() {
    return {
      showLogin: false,
      identityToken: null,
      isAuthenticated: false,

      futureSchedules: null,
      pastSchedules: null,

      zipCode: null,

      loginData: {
        emailAddress: null,
        password: null,
      },

      taxReceiptEmailAddress: null,

      taxReceiptPrepScheduleIds: [],
      taxReceiptSentScheduleIds: []
    };
  },
  computed: {
    pastSchedulesRows: function () {
      if (!this.pastSchedules)
        return null;

      let retVal = [];

      this.pastSchedules.forEach(schedule => {
        retVal.push({
          type: 'data',
          schedule: schedule
        });
        retVal.push({
          type: 'taxReceiptPrep',
          schedule: schedule
        });
        retVal.push({
          type: 'taxReceiptSent',
          schedule: schedule
        });
      });

      return retVal;
    }
  },
  methods: {
    initWithParams: function () {
      this.identityToken = null;
      this.showLogin = false;
      this.isAuthenticated = false;

      if (localStorage.getItem('identityToken')) {
        this.identityToken = localStorage.getItem('identityToken');
        this.isAuthenticated = true;
      }

      // An identity token in the URL supercedes a stored identity token
      if (this.$route.query.identityToken)
        this.identityToken = this.$route.query.identityToken;

      this.init();
    },
    init: function () {
      if (!this.identityToken) {
        this.showLogin = true;
        return;
      }

      this.showlogin = false;

      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/dashboard', {
          identityToken: this.identityToken,
        })
        .then(response => {
          this.futureSchedules = response.data.futureSchedules;
          this.pastSchedules = response.data.pastSchedules;

          if (this.futureSchedules && this.futureSchedules.length > 0)
            this.zipCode = this.futureSchedules[0].zipCode;
          else if (this.pastSchedules && this.pastSchedules.length > 0)
            this.zipCode = this.pastSchedules[0].zipCode;
        })
        .catch(error => {
          console.log(error);
        });
    },
    onLogout: function (e) {
      localStorage.removeItem('identityToken');
      this.identityToken = null;
      this.isAuthenticated = false;
      this.init();
    },
    onNewSchedule: function (e) {
      e.preventDefault();

      this.$router.push({ path: 'schedule', query: {
        zipCode: this.zipCode,
        identityToken: this.identityToken,
      }});
    },
    onSubmitLogin: function (e) {
      e.preventDefault();

      if (!this.$refs.loginControl.isValid()) {
        this.isLoginValid = false;
        return;
      }

      this.isLoginValid = true;

      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/login', this.loginData)
        .then(response => {
          if (!response.data.isSuccess) {
            console.log(response.data.errorMessage);
            return;
          }

          this.identityToken = response.data.identityToken;
          this.isAuthenticated = true;
          localStorage.setItem('identityToken', this.identityToken);

          this.showLogin = false;
          this.init();
        })
        .catch(error => {
          console.log(error);
        });      
    },
    toggleTaxReceiptPrep: function (scheduleId) {
      if (this.taxReceiptPrepScheduleIds.includes(scheduleId))
        this.taxReceiptPrepScheduleIds = this.taxReceiptPrepScheduleIds.filter(x => x !== scheduleId);
      else
        this.taxReceiptPrepScheduleIds.push(scheduleId);
    },
    emailTaxReceipt: function (scheduleId) {
      this.pushNavigateActionToDataLayer('Email Tax Receipt');

      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/emailTaxReceipt', {
          scheduleId: scheduleId,
          identityToken: this.identityToken
        })
        .then(response => {
          this.toggleTaxReceiptPrep(scheduleId);
          this.taxReceiptSentScheduleIds.push(scheduleId);
        })
        .catch(error => {
          console.log(error);
        });      
    }
    // generateTaxReceipt: function (scheduleId) {
    //   this.pushNavigateActionToDataLayer('Tax Receipt');

    //   axios
    //     .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/generateTaxReceipt', {
    //       scheduleId: scheduleId,
    //       identityToken: this.identityToken
    //     }, {
    //       responseType: 'blob'
    //     })
    //     .then(response => {
    //       saveAs.saveAs(response.data, "TaxReceipt.pdf");
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });      
    // }
  },
  watch: {
    $route () {
      this.initWithParams();
    }
  },
  mounted () {
    this.initWithParams();
  }  
}
</script>
