<template>
    <div>
        <b-form-row>
            <b-col>
                <b-form-group label="Please select your pickup date." v-if="!schedule.id || rescheduleOnly">
                    <b-calendar v-model="schedule.pickupDate" :date-disabled-fn="dateDisabled" block></b-calendar>
                    <div class="text-center my-2 py-2 border bg-light">
                        Your pickup date is <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span>.
                    </div>
                </b-form-group>
                <b-form-group v-if="schedule.id && !rescheduleOnly">
                    <div class="text-center my-2 py-2 border bg-light">
                        Your pickup date is <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span>.
                        <div><b-link :to="getRescheduleUrl(schedule)">Reschedule My Pickup</b-link></div>
                    </div>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row v-if="!rescheduleOnly">
            <b-col>
                <radio-button-list-control v-model="schedule.locationTypeId" :options="locationTypesSelectOptions" name="locationTypeId" label="Where will you leave your donation?" />
            </b-col>
        </b-form-row>
        <b-form-row v-if="!rescheduleOnly">
            <b-col>
                <radio-button-list-control v-model="schedule.gateCodeRequired" required required-error="Please indicate if a gate code is required to access your donation." :options="gateCodeRequiredSelectOptions" name="gateCodeRequired" label="Will the driver need a gate code to access your donation?" />
                <text-control v-if="schedule && schedule.gateCodeRequired" v-model="schedule.gateCode" placeholder="Gate Code" required />
            </b-col>
        </b-form-row>
        <b-form-row v-if="!rescheduleOnly">
            <b-col>
                <textarea-control v-model="schedule.instructions" label="Do you have any special instructions or comments?" placeholder="Special Instructions" />
            </b-col>
        </b-form-row>
        <b-form-row v-if="!hasAccount && !rescheduleOnly">
            <b-col>
                <text-control v-model="schedule.password" type="password" :custom-validation="validatePassword" label="If you would like to create an account, please create a password." description="Creating an account is optional. But by creating an account, you'll be able to schedule future pickups more easily." placeholder="New Password" />
                <text-control v-if="schedule.password" v-model="schedule.confirmPassword" type="password" :custom-validation="validateConfirmPassword" label="Please confirm your new password" placeholder="Confirm Password" />
            </b-col>
        </b-form-row>
    </div>
</template>

<script>
    import TextareaControl from '../../../../ClientApp/src/components/Controls/TextareaControl.vue';
    import FormMixin from '../mixins/FormMixin.vue';
    import FormattingMixin from '../mixins/FormattingMixin.vue';

    export default {
        components: { TextareaControl },
        name: 'ScheduleControl',
        mixins: [FormMixin, FormattingMixin],
        props: {
            hasAccount: null,
            schedule: null,
            locationTypes: null,
            zipCodeAvailabilities: null,
            rescheduleOnly: {
                default: false
            },
            identityToken: null
        },
        data() {
            return {
                gateCodeRequiredSelectOptions: [
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                ]
            };
        },
        computed: {
            locationTypesSelectOptions: function () {
                if (!this.locationTypes)
                    return null;

                return this.locationTypes.filter(x => x.name != 'Dropoff Container').map(x => ({ text: x.name, value: x.id }));
            },
        },
        methods: {
            isValid: function () {
                return this.isFormValid();
            },
            dateDisabled: function (ymd) {
                if (!this.zipCodeAvailabilities)
                    return true;

                return !this.zipCodeAvailabilities.some(x => x.date == ymd && x.availability == 'Available');
            },
            validatePassword: function (newValue) {
                if (newValue && newValue.length < 10)
                    return 'Please choose a password that\'s at least 10 characters.';

                return true;
            },
            validateConfirmPassword: function (newValue) {
                if (!newValue && this.schedule.password || newValue != this.schedule.password)
                    return 'Please make sure your password confirmation matches your new password.';

                return true;
            },
        },
        mounted() {
        }
    }
</script>
