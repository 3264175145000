import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

// To keep the size of our build small, we're not using the entire BootstrapVue library, just certain plugins
import { LayoutPlugin, TabsPlugin, FormPlugin, FormInputPlugin, FormSelectPlugin, ButtonPlugin, LinkPlugin, CardPlugin, FormCheckboxPlugin, FormGroupPlugin, CalendarPlugin, FormRadioPlugin, FormTextareaPlugin, AlertPlugin, TableSimplePlugin, ButtonGroupPlugin } from 'bootstrap-vue'

import TextControl from '../../ClientApp/src/components/Controls/TextControl.vue'
import TextareaControl from '../../ClientApp/src/components/Controls/TextareaControl.vue'
import SelectListControl from '../../ClientApp/src/components/Controls/SelectListControl.vue'
import CheckboxControl from '../../ClientApp/src/components/Controls/CheckboxControl.vue'
import RadioButtonListControl from '../../ClientApp/src/components/Controls/RadioButtonListControl.vue'
import CheckBoxListControl from '../../ClientApp/src/components/Controls/CheckBoxListControl.vue'

Vue.use(LayoutPlugin);
Vue.use(TabsPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(LinkPlugin);
Vue.use(CardPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(CalendarPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(AlertPlugin);
Vue.use(TableSimplePlugin);
Vue.use(ButtonGroupPlugin);

Vue.use(VueRouter);

import FormattingMixin from './components/mixins/FormattingMixin.vue'
import DataLayerMixin from './components/mixins/DataLayerMixin.vue'

Vue.use(FormattingMixin);
Vue.use(DataLayerMixin);

// Pages
import SchedulePage from './components/pages/Schedule'
import DashboardPage from './components/pages/Dashboard'
import CancelPage from './components/pages/Cancel'
import ReschedulePage from './components/pages/Reschedule'
import ConfirmPage from './components/pages/Confirm'
import RedirectPage from './components/pages/Redirect'
import UnsubscribePage from './components/pages/Unsubscribe'

// Controls
import YourInfoControl from './components/controls/YourInfoControl'
import YourInfoStaticControl from './components/controls/YourInfoStaticControl'
import DonatedItemsControl from './components/controls/DonatedItemsControl'
import UpsItemsControl from './components/controls/UpsItemsControl'
import ScheduleControl from './components/controls/ScheduleControl'
import LoginControl from './components/controls/LoginControl'

Vue.component('text-control', TextControl);
Vue.component('textarea-control', TextareaControl);
Vue.component('select-list-control', SelectListControl);
Vue.component('checkbox-control', CheckboxControl);
Vue.component('radio-button-list-control', RadioButtonListControl);
Vue.component('check-box-list-control', CheckBoxListControl);

Vue.component('your-info-control', YourInfoControl);
Vue.component('your-info-static-control', YourInfoStaticControl);
Vue.component('donated-items-control', DonatedItemsControl);
Vue.component('ups-items-control', UpsItemsControl);
Vue.component('schedule-control', ScheduleControl);
Vue.component('login-control', LoginControl);

Vue.config.productionTip = false

const routes = [
  { path: '/', component: DashboardPage },
  { path: '/schedule', component: SchedulePage },
  { path: '/cancel', component: CancelPage },
  { path: '/reschedule', component: ReschedulePage },
  { path: '/confirm', component: ConfirmPage },
  { path: '/unsubscribe', component: UnsubscribePage },

  { path: '/s/:shortCode?', component: RedirectPage, props: route => ({ source: 'email', type: 'schedule', shortCode: route.params.shortCode })},
  { path: '/c/:shortCode?', component: RedirectPage, props: route => ({ source: 'email', type: 'confirm', shortCode: route.params.shortCode })},
  { path: '/x/:shortCode?', component: RedirectPage, props: route => ({ source: 'email', type: 'cancel', shortCode: route.params.shortCode })},
  { path: '/r/:shortCode?', component: RedirectPage, props: route => ({ source: 'email', type: 'reschedule', shortCode: route.params.shortCode })},
  { path: '/d/:shortCode?', component: RedirectPage, props: route => ({ source: 'email', type: 'dashboard', shortCode: route.params.shortCode })},
  { path: '/u/:shortCode?', component: RedirectPage, props: route => ({ source: 'email', type: 'unsubscribe', shortCode: route.params.shortCode })},

  { path: '/ss/:shortCode?', component: RedirectPage, props: route => ({ source: 'sms', type: 'schedule', shortCode: route.params.shortCode })},
  { path: '/cs/:shortCode?', component: RedirectPage, props: route => ({ source: 'sms', type: 'confirm', shortCode: route.params.shortCode })},
  { path: '/xs/:shortCode?', component: RedirectPage, props: route => ({ source: 'sms', type: 'cancel', shortCode: route.params.shortCode })},
  { path: '/rs/:shortCode?', component: RedirectPage, props: route => ({ source: 'sms', type: 'reschedule', shortCode: route.params.shortCode })},
  { path: '/ds/:shortCode?', component: RedirectPage, props: route => ({ source: 'sms',  type: 'dashboard', shortCode: route.params.shortCode })},
  //{ path: '/us/:shortCode?', component: RedirectPage, props: route => ({ source: 'sms',  type: 'unsubscribe', shortCode: route.params.shortCode })},

  { path: '/sm/:shortCode?', component: RedirectPage, props: route => ({ source: 'msms', type: 'schedule', shortCode: route.params.shortCode })},
  { path: '/cm/:shortCode?', component: RedirectPage, props: route => ({ source: 'msms', type: 'confirm', shortCode: route.params.shortCode })},
  { path: '/xm/:shortCode?', component: RedirectPage, props: route => ({ source: 'msms', type: 'cancel', shortCode: route.params.shortCode })},
  { path: '/rm/:shortCode?', component: RedirectPage, props: route => ({ source: 'msms', type: 'reschedule', shortCode: route.params.shortCode })},
  { path: '/dm/:shortCode?', component: RedirectPage, props: route => ({ source: 'msms',  type: 'dashboard', shortCode: route.params.shortCode })},
  //{ path: '/um/:shortCode?', component: RedirectPage, props: route => ({ source: 'msms',  type: 'unsubscribe', shortCode: route.params.shortCode })},
];

const router = new VueRouter({
  routes
});

new Vue({
  router,
  render: h => h(App),
  mixins: [DataLayerMixin],
  data() {
    return {
      channelId: 0,
      organizationId: 0
    }
  },
  watch: {
    $route(to, from) {
      this.pushToDataLayer({
        event: 'navigate-event',
        navigatedTo: to.path
      });
    }
  }
}).$mount('#app')
