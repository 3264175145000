<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="12" md="2" lg="3">
        </b-col>
        <b-col md="8" lg="6">
          <img src="https://cdn.maropost.com/pro/uploads/account_1301/42/Donation-Pickup-Service-Support-VVA.jpg" />

          <hr />

          <div v-if="isSuccess">
            <p>You have paused all emails from us for the next month.</p>
            <p>We look forward to serving you again.</p>

            <p>If you decide that you would like to schedule a free donation pickup before those months are up, we'll be happy to help! Just <b-link :to="getDashboardUrl()">CLICK HERE</b-link> to schedule your pickup.</p>
          </div>

          <div v-if="!isSuccess">
            <p>Thank you! You've previously helped U.S. veterans in need with your donations and we're grateful for your support!</p>
            <p>The generous donations from people like YOU make a big difference in the lives of American's veterans and their families. The items you donate generate the majority of the funding to support local, state and national programs of the Vietnam Veterans of America. Your ongoing support is greatly appreciated.</p>
            <p>If you know you won't need our help for a few months, we'll be happy to put your emails on a pause. Just click one of the buttons below:</p>

            <b-form @submit="onSnooze(30)">
              <div class="text-center">
                <b-button type="submit" variant="success" class="w-100 p-2 my-2">Snooze All Emails for 1 Month</b-button>
              </div>
            </b-form>
            <b-form @submit="onSnooze(90)">
              <div class="text-center">
                <b-button type="submit" variant="success" class="w-100 p-2 my-2">Snooze All Emails for 3 Months</b-button>
              </div>
            </b-form>
            <b-form @submit="onSnooze(180)">
              <div class="text-center">
                <b-button type="submit" variant="success" class="w-100 p-2 my-2">Snooze All Emails for 6 Months</b-button>
              </div>
            </b-form>
            <hr />
            <p>Our mission is to help America's veterans, which is why from time to time we reach out to people like you who are in need of our free donation services. If you have a question about what donations we accept, what locations we serve, or how we support veterans, all you need to do is click below and ask us via Facebook Messenger!</p>
            <img src="https://cdn.maropost.com/pro/uploads/account_1301/72/logo_fb_msg.png" />
            <hr />
            <p>If you'd rather completely unsubscribe, we understand - the last thing we want to do is create more clutter in your inbox! We'll sorry to see you go, but just click the button below and we'll no longer email you.</p>
            <b-form @submit="onSnooze(-1)">
              <div class="text-center">
                <b-button type="submit" class="w-100 p-2 my-2">Unsubscribe From All Emails</b-button>
              </div>
            </b-form>
          </div>
        </b-col>
        <b-col sm="12" md="2" lg="3">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Constants from '../../store/constants'
import axios from 'axios'
import FormattingMixin from '../mixins/FormattingMixin.vue';

export default {
  name: 'UnsubscribePage',
  mixins: [FormattingMixin],
  props: {
  },
  data() {
    return {
      identityToken: null,
      isSuccess: false,
    };
  },
  computed: {
  },
  methods: {
    initWithParams: function () {
      this.identityToken = null;
      this.isAuthenticated = false;

      if (localStorage.getItem('identityToken')) {
        this.identityToken = localStorage.getItem('identityToken');
        this.isAuthenticated = true;
      }

      // An identity token in the URL supercedes a stored identity token
      if (this.$route.query.identityToken)
        this.identityToken = this.$route.query.identityToken;

      this.isSuccess = false;
    },
    onSnooze: function (days) {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/pauseMarketingEmail', {
          identityToken: this.identityToken,
          all: days == -1,
          days: days > 0 ? days : null
        })
        .then(response => {
          this.isSuccess = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  watch: {
    $route () {
      this.initWithParams();
    }
  },
  mounted () {
    this.initWithParams();
  }  
}
</script>
