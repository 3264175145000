<template>
  <div>
    Please wait, redirecting...
  </div>
</template>

<script>
import Constants from '../../store/constants'
import axios from 'axios'

export default {
  name: 'RedirectPage',
  props: {
    type: null,
    shortCode: null,
    source: null
  },
  data() {
    return {
      response: null,
    };
  },
  computed: {
  },
  methods: {
    init: function () {
      if (!this.shortCode) {
        this.redirect(null);
      } else {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/redirect', {
            shortCode: this.shortCode
          })
          .then(response => {
            this.redirect(response.data);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    redirect: function (data) {
      this.response = data;

      let suffix = '';

      if (this.source)
        suffix += '&source=' + this.source;

      if (this.source && this.source == 'msms')
        suffix += '&xA=10101';

      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('xA'))
        suffix += '&xA=' + searchParams.get('xA');
      if (searchParams.has('channelId'))
        suffix += '&channelId=' + searchParams.get('channelId');

      let queryString = '?' + suffix;

      if (this.response)
        queryString = '?zipCode=' + this.response.zipCode + '&identityToken=' + this.response.identityToken + '&scheduleId=' + this.response.scheduleId + suffix;

      if (this.type == 'schedule')
        this.$router.push('/schedule' + queryString);
      else if (this.type == 'confirm')
        this.$router.push('/confirm' + queryString);
      else if (this.type == 'cancel')
        this.$router.push('/cancel' + queryString);
      else if (this.type == 'reschedule')
        this.$router.push('/reschedule' + queryString);
      else if (this.type == 'unsubscribe')
        this.$router.push('/unsubscribe' + queryString);

      if (this.type == 'dashboard' && this.response)
        this.$router.push('/?identityToken=' + this.response.identityToken + suffix);
      else if (this.type == 'dashboard')
        this.$router.push('/schedule' + queryString);
    }
  },
  watch: {
    $route () {
      this.init();
    }
  },
  mounted () {
    this.init();
  }  
}
</script>
