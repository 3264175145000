<script>
import { DateTime } from 'luxon';

export default {
  name: "DataLayerMixin",
  props: [
  ],
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    getDataLayer: function () {
      window.dataLayer = window.dataLayer = window.dataLayer || [];
      return window.dataLayer;
    },
    pushToDataLayer: function (obj) {
      let dataLayer = this.getDataLayer();

      dataLayer.push(obj);

      console.log('Push to DataLayer')
      console.log(dataLayer);
    },
    pushNavigateActionToDataLayer: function (navigateAction) {
      this.pushToDataLayer({
        event: 'navigate-event',
        navigateAction: navigateAction
      });
    },
    pushScheduleToDataLayer: function (schedule, event, earliestPickupDate) {
      console.log('Push Schedule');
      console.log(schedule);

      let dt1 = DateTime.fromISO(schedule.pickupDate).set({ hour: 0, minutes: 0, seconds: 0});
      let dt2 = DateTime.now().set({ hour: 0, minutes: 0, seconds: 0});
      let diffDaysToPickup = Math.ceil(dt1.diff(dt2, ['days']).values.days);

      let diffDaysFromFirst = 0;

      if (earliestPickupDate) {
        let dt3 = DateTime.fromISO(earliestPickupDate).set({ hour: 0, minutes: 0, seconds: 0});
        diffDaysFromFirst = Math.ceil(dt1.diff(dt3, ['days']).values.days);
      }

      this.pushToDataLayer({
        event: event,
        type: schedule.type,

        scheduleId: schedule.id,
        donorId: schedule.donorId,
        outletId: schedule.outletId,
        operatorId: schedule.operatorId,
        organizationId: schedule.organizationId,

        pickupCity: schedule.city,
        pickupState: schedule.state,
        pickupZip: schedule.zipCode,

        items: schedule.itemTypes.map(x => x.itemTypeId).join(','),
        qtyBoxes: schedule.sizeTypeId,
        qtyUpsBoxes: schedule.numberOfBoxes,
        location: schedule.locationTypeId,

        selectedPickupDate: schedule.pickupDate,
        firstAvailablePickupDate: earliestPickupDate,
        daysFromFirstAvailablePickup: diffDaysFromFirst,
        daysToPickup: diffDaysToPickup,
      })
    }
  },
  mounted () {
  }
};
</script>