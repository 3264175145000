<template>
  <div>
    <b-form-row>
      <b-col>
        <div>{{schedule.donor.firstName}} {{schedule.donor.lastName}}</div>
        <div>{{schedule.addressLine1}}</div>
        <div v-if="schedule.addressLine2">{{schedule.addressLine2}}</div>
        <div>{{schedule.city}}, {{schedule.state}} {{schedule.zipCode}}</div>
        <div v-if="schedule.emailAddress">{{schedule.emailAddress}}</div>
        <div v-if="schedule.phoneNumber">{{schedule.phoneNumber}}</div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import FormMixin from '../mixins/FormMixin.vue';

export default {
  name: 'YourInfoStaticControl',
  mixins: [FormMixin],
  props: {
    schedule: null
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted () {
  }  
}
</script>
