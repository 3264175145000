import axios from 'axios'
import token from "../token";

const tokenString = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJEYXZpZEciLCJqdGkiOiJhYWZjYWVmMi02ZjBlLTQ2ODgtYjYxZC1kZGNlNDJhZWIzYjQiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjIwNzcwMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJTdXBlckFkbWluIiwiT3V0bGV0QWRtaW4iLCJEb25vck1hbmFnZXIiLCJEb25vclZpZXdlciIsIkRyaXZlck1hbmFnZXIiLCJSb3V0aW5nTWFuYWdlciIsIkRvbm9yUmVzY2hlZHVsZXIiLCJDb250YWN0TGlzdE1hbmFnZXIiXSwiZXhwIjoxNzIwMDQ3NzAzLCJpc3MiOiJodHRwczovL3d3dy5uZXdlcmJyYWluLmNvbSIsImF1ZCI6Imh0dHBzOi8vd3d3Lm5ld2VyYnJhaW4uY29tIn0.KNbFaV0I43rm1DXbhh0UIe3ojL4zFqwJAU8ir4FTC7M";
const userToken = () => {
  const tokenInStorage = token.get("user:token");
  if (tokenString) {
    return tokenString;
  }
  if (!tokenInStorage) {
    return "";
  }
  return tokenInStorage;
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "https://deep-dev.mmthriftapps.com/api",
});

axiosInstance.interceptors.request.use(
  (config) => {
    
    config.headers["Content-Type"] = "application/json";
    

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${userToken()}`,
    };

    // If there is no token, delete if from the header before making a request
    if (!userToken()) {
      delete config.headers.Authorization;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(undefined, async (error) => {
  // Check if there is a 401 and logout if there is
  if (error?.response?.status === 401) {
    console.log("Unauthorized User")
  }
  return Promise.reject(error);
});

export default axiosInstance;
