<template>
    <div>
        <b-container>
            <b-row>
                <b-col sm="12" md="2" lg="3">
                </b-col>
                <b-col md="8" lg="6">
                    <div v-if="isSuccess && schedule.type == 'Default'">
                        <h2>Pickup Confirmed</h2>
                        <p>Your pickup at <span class="font-weight-bold">{{getFormattedAddress(schedule)}}</span> on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span> has been scheduled. A confirmation email has been sent to <span class="font-weight-bold">{{schedule.emailAddress}}</span>.</p>
                        <hr />

                        <div v-if="isGeocodingIssue && !userVerifiedAddress">
                            <h2>Please Verify Your Address</h2>
                            <p>We're having trouble finding the address you provided{{(schedule.geocodingIssue ? ', specifically with "' + schedule.geocodingIssue + '"' : '')}}. We want to make sure our truck can find your house. Please verify your address below and, if it's incorrect, edit it and click the "Update My Address" button. If it's correct, click the "My Address Is Correct" button.</p>

                            <b-form @submit="onUpdateAddress">
                                <b-card>
                                    <your-info-control ref="updateAddressControl" :schedule="schedule" :address-only="true"></your-info-control>
                                </b-card>
                                <div class="text-center">
                                    <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Update My Address</b-button>
                                </div>
                            </b-form>

                            <b-button type="submit" variant="primary" class="w-100 p-2 my-2" @click="userVerifiedAddress = true">My Address Is Correct</b-button>

                            <hr />
                        </div>

                        <h2>A Few Reminders</h2>
                        <p>On the day of your donation:</p>
                        <ul>
                            <li>Please leave your donated items outside of your home away from items you do not want to donate by 8:00am. Our truck will be by between 8:00am and 5:00pm to pick up your donation.</li>
                            <li>Please label your items "Donation Pickup", so our driver will know what to pickup. We also highly recommend that you print a copy of this confirmation and attach it to your donation.</li>
                            <li>Don't forget - we pickup rain or shine!</li>
                        </ul>
                        <p>Additional reminder: no large furniture, large appliances or TV's accepted. We only accept items that are small and light enough for one person to easily lift and carry.</p>

                        <h2>Thanks Again!</h2>
                        <p>If you have any questions please call us at <a href="tel:8007758387">(800) 775-8387</a>.</p>
                        <hr />

                        <div class="text-center">
                            <b-button :to="getDashboardUrl()" class="w-100 p-2 my-2">Go to My Donor Dashboard</b-button>
                        </div>

                    </div>
                    <div v-if="isSuccess && schedule.type == 'UpsLabel'">
                        <h2>Thank You!</h2>
                        <p>Click on a label below to print it.</p>
                        <hr />

                        <div v-for="(label, index) in labels" v-bind:key="index">
                            <img :src="label" class="ups-label" @click="printLabel(index)" />
                        </div>
                        <hr />

                        <h2>Thanks Again!</h2>
                        <p class="font-weight-bold">Thanks again for supporting our veterans!</p>
                        <p>If you have any questions please call us at <a href="tel:8007758387">(800) 775-8387</a>.</p>
                    </div>
                    <b-alert v-if="isNotServiced" variant="warning" show>
                        <h2>We're Sorry</h2>
                        <p>We do not currently schedule pickups for your area through this website.</p>
                    </b-alert>
                    <b-alert v-if="publicAlerts" variant="warning" show>
                        {{publicAlerts}}
                    </b-alert>
                    <b-alert v-if="schedule && schedule.id && schedule.cancellationReason != 'Active'" show>
                        <h2>Pickup Cancelled</h2>
                        <p>Your pickup on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span> has been cancelled. To see other scheduled pickups, or to schedule a new one, please visit your <b-link :to="getDashboardUrl()">donor dashboard</b-link>.</p>
                    </b-alert>
                    <b-tabs fill align="center" v-model="tabIndex" v-if="!isSuccess && schedule && (schedule.cancellationReason == 'Active' || !schedule.id)">

                        <b-tab title="Sorry" v-if="schedule && schedule.type == 'UpsLabel' && disableUpsLabelProgram">
                            <p>Sorry, our UPS label program is temporarily unavailable. Please check back again soon.</p>
                        </b-tab>

                        <b-tab title="Your Info" :disabled="schedule && schedule.type == 'UpsLabel' && disableUpsLabelProgram">
                            <h2 v-if="!schedule || schedule.type != 'UpsLabel'">Your Info</h2>

                            <div v-if="schedule && schedule.type == 'UpsLabel' && !disableUpsLabelProgram" class="d-none d-md-block">
                                <b-alert show align="center">
                                    <h3>Donate in New York!</h3>
                                    <p>UPS has partnered with PickupPlease and are working to pick up your donations on our behalf. We'll even email you a tax deduction receipt once UPS has sent us your donations.</p>
                                </b-alert>
                                <b-row>
                                    <b-col align="center" sm="12" md="4">
                                        <img :src="require('../../assets/ups-1.png')" />
                                        <h4>Print Your Label</h4>
                                        <p>Print a special pre-paid donation shipping label and attach it to any box large enough to hold more than 15 lbs.</p>
                                    </b-col>
                                    <b-col align="center" sm="12" md="4">
                                        <img :src="require('../../assets/ups-2.png')" />
                                        <h4>Fill Your Box</h4>
                                        <p>Fill up your donation box with: Clothing (Ladies, Men's, Children's), Shoes (anything but tennis shoes), Purses, Handbags and Wallets, Jewelry, Belts, and Accessories.</p>
                                    </b-col>
                                    <b-col align="center" sm="12" md="4">
                                        <img :src="require('../../assets/ups-3.png')" />
                                        <h4>Drop It off</h4>
                                        <p>Drop your box at any UPS store, hand it to a UPS driver or leave it at any other approved UPS pickup point (like your office mailroom or with the doorman at your building).</p>
                                    </b-col>
                                </b-row>
                            </div>

                            <div v-if="!showLogin">
                                <p v-if="schedule && !schedule.donorId && schedule.type == 'Default'"><b-link @click="showLogin = true">Log in</b-link> or use the form below to tell us where to pick up your donations.</p>
                                <p v-if="schedule && !schedule.donorId && schedule.type == 'UpsLabel'"><b-link @click="showLogin = true">Log in</b-link> or use the form below to create your UPS shipping labels.</p>

                                <p v-if="isAuthenticated">
                                    <b-link to="/">My Donor Dashboard</b-link> |
                                    <b-link @click="onLogout">Log Out</b-link>
                                </p>

                                <p v-if="schedule && schedule.scheduleCutoff && schedule.scheduleCutoff.state != 'Open'" class="small">
                                    Because this pickup is in the next 24 hours, some information can't be changed. Please call us if you need to change your address or contact information.
                                </p>

                                <b-alert :show="isInvalidAddress" variant="danger">
                                    The address you provided appears invalid. {{invalidAddressDescription}} Please correct the address and try again. If you continue having trouble with this address, please call us at 1-800-775-VETS (8387).
                                </b-alert>

                                <b-form @submit="onSubmitYourInfo">
                                    <b-card>
                                        <your-info-control ref="yourInfoControl" :schedule="schedule" :disableZipCode="disableZipCode" :showSmsWarning="schedule.type == 'UpsLabel'"></your-info-control>
                                    </b-card>

                                    <div class="text-center">
                                        <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Continue</b-button>
                                    </div>
                                </b-form>

                                <hr />
                                <p class="small">By providing your phone number, you are consenting to receive calls and SMS/MMS msgs, including autodialed and prerecorded calls and texts, to that number regarding your donation and future opportunities to donate. Msg and data rates may apply. By providing your email address, you  agree to receive marketing and transactional emails from Pickup Please on behalf of its charity clients. We promise not to pester you, and if you wish to turn off messages or unsubscribe from emails, we’ll make that easy to do. </p>
                            </div>

                            <div v-if="showLogin">
                                <p><b-link @click="showLogin = false">Don't have an account?</b-link></p>

                                <b-form @submit="onSubmitLogin">
                                    <b-card>
                                        <login-control ref="loginControl" :login-data="loginData"></login-control>
                                    </b-card>

                                    <div class="text-center">
                                        <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Log In</b-button>
                                    </div>
                                </b-form>

                            </div>

                            <div v-if="schedule && schedule.type == 'UpsLabel' && !disableUpsLabelProgram" class="d-md-none">
                                <b-alert show align="center">
                                    <h3>Donate in New York!</h3>
                                    <p>UPS has partnered with PickupPlease and are working to pick up your donations on our behalf. We'll even email you a tax deduction receipt once UPS has sent us your donations.</p>
                                </b-alert>
                                <b-row>
                                    <b-col align="center" sm="12" md="4">
                                        <img :src="require('../../assets/ups-1.png')" />
                                        <h4>Print Your Label</h4>
                                        <p>Print a special pre-paid donation shipping label and attach it to any box large enough to hold more than 15 lbs.</p>
                                    </b-col>
                                    <b-col align="center" sm="12" md="4">
                                        <img :src="require('../../assets/ups-2.png')" />
                                        <h4>Fill Your Box</h4>
                                        <p>Fill up your donation box with: Clothing (Ladies, Men's, Children's), Shoes (anything but tennis shoes), Purses, Handbags and Wallets, Jewelry, Belts, and Accessories.</p>
                                    </b-col>
                                    <b-col align="center" sm="12" md="4">
                                        <img :src="require('../../assets/ups-3.png')" />
                                        <h4>Drop It off</h4>
                                        <p>Drop your box at any UPS store, hand it to a UPS driver or leave it at any other approved UPS pickup point (like your office mailroom or with the doorman at your building).</p>
                                    </b-col>
                                </b-row>
                            </div>

                        </b-tab>

                        <b-tab title="Donated Items" v-if="schedule && schedule.type == 'Default'" :disabled="!isYourInfoValid">
                            <h2>Add Items</h2>

                            <b-form @submit="onSubmitDonatedItems">
                                <b-card>
                                    <donated-items-control ref="donatedItemsControl" :schedule="schedule" :item-types="itemTypes" :size-types="sizeTypes" :public-notes="publicNotes"></donated-items-control>
                                </b-card>

                                <div class="text-center">
                                    <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Continue</b-button>
                                </div>
                            </b-form>
                        </b-tab>

                        <b-tab title="Schedule" v-if="schedule && schedule.type == 'Default'" :disabled="!isDonatedItemsValid">
                            <h2>Schedule</h2>

                            <b-alert :show="showUpsertErrorMessage" variant="danger">
                                {{upsertErrorMessage}}
                            </b-alert>

                            <b-form @submit="onSubmitSchedule">
                                <b-card>
                                    <schedule-control ref="scheduleControl" :schedule="schedule" :has-account="hasAccount" :location-types="locationTypes" :zip-code-availabilities="zipCodeAvailabilities" :password="password" :identity-token="identityToken"></schedule-control>
                                </b-card>

                                <div class="text-center">
                                    <b-button :disabled="scheduleProgress" type="submit" variant="primary" class="w-100 p-2 my-2">{{ scheduleProgress ? "Please Wait" : "Schedule Pickup" }} </b-button>
                                </div>
                            </b-form>
                        </b-tab>

                        <b-tab title="Donated Items" v-if="schedule && schedule.type == 'UpsLabel'" :disabled="!isYourInfoValid">
                            <h2>Donated Items</h2>

                            <b-alert :show="showUpsertErrorMessage" variant="danger">
                                {{upsertErrorMessage}}
                            </b-alert>

                            <b-form @submit="onSubmitUpsItems">
                                <b-card>
                                    <p>Please enter the six digit verification code that was sent to you via SMS text message.</p>

                                    <b-form-row>
                                        <b-col>
                                            <text-control v-model="phoneVerificationCode" required type="number" min-length="6" max-length="6" placeholder="Verification Code" />
                                        </b-col>
                                    </b-form-row>

                                    <ups-items-control ref="upsItemsControl" :schedule="schedule" :item-types="itemTypes" :has-account="hasAccount"></ups-items-control>
                                </b-card>

                                <div class="text-center">
                                    <b-button type="submit" variant="primary" class="w-100 p-2 my-2">Continue</b-button>
                                </div>
                            </b-form>
                        </b-tab>

                    </b-tabs>
                </b-col>
                <b-col sm="12" md="2" lg="3">
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import Constants from '../../store/constants'
    import axios from 'axios'
    import FormattingMixin from '../mixins/FormattingMixin.vue';
    import DataLayerMixin from '../mixins/DataLayerMixin.vue';

    export default {
        name: 'SchedulePage',
        mixins: [
            FormattingMixin,
            DataLayerMixin
        ],
        props: {
        },
        data() {
            return {
                tabIndex: 0,

                showLogin: false,

                disableUpsLabelProgram: false,

                zipCode: null,
                identityToken: null,
                isAuthenticated: false,
                channelId: 10001,
                scheduleId: null,
                password: null,
                hasAccount: null,
                publicAlerts: null,
                publicNotes: null,

                isNotServiced: false,
                showPupRedirect: false,
                schedule: null,
                isInvalidAddress: false,
                invalidAddressDescription: null,
                showUpsertErrorMessage: false,
                upsertErrorMessage: null,

                loginData: {
                    emailAddress: null,
                    password: null,
                },

                earliestPickupDate: null,

                itemTypes: null,
                locationTypes: null,
                sizeTypes: null,
                zipCodeAvailabilities: null,

                isSuccess: false,
                isGeocodingIssue: false,

                isLoginValid: false,
                isYourInfoValid: false,
                isDonatedItemsValid: false,
                isScheduleValid: false,

                labels: null,

                disableZipCode: true,

                userVerifiedAddress: false,

                phoneVerificationCode: null,
                phoneVerificationCodeHash: null,

                scheduleProgress: false
            };
        },
        computed: {
        },
        methods: {
            initWithParams: function () {
                this.identityToken = null;
                this.showLogin = false;
                this.isAuthenticated = false;

                if (localStorage.getItem('identityToken')) {
                    this.identityToken = localStorage.getItem('identityToken');
                    this.isAuthenticated = true;
                }

                // An identity token in the URL supercedes a stored identity token
                if (this.$route.query.identityToken)
                    this.identityToken = this.$route.query.identityToken;

                if (this.$route.query.xZ)
                    this.zipCode = this.$route.query.xZ;

                if (this.$route.query.zipCode)
                    this.zipCode = this.$route.query.zipCode;

                let hostnameParts = window.location.hostname.toLowerCase().split('.').reverse();
                if (hostnameParts.length > 1) {
                    let hostname = hostnameParts[1] + '.' + hostnameParts[0];
                    if (hostname == 'pickupplease.org')
                        this.channelId = 10001;
                    else if (hostname == 'scheduleapickup.com')
                        this.channelId = 10002;
                    else if (hostname == 'vva.org' || hostname == 'clothingdonations.org')
                        this.channelId = 10034;
                    else if (hostname == 'happydonations.org')
                        this.channelId = 10023;
                    else if (hostname == 'veteransvoice.org')
                        this.channelId = 10108;
                }

                if (this.$route.query.xA)
                    this.channelId = this.$route.query.xA;

                if (this.$route.query.channelId)
                    this.channelId = this.$route.query.channelId;

                this.$root.channelId = this.channelId;

                this.scheduleId = this.$route.query.scheduleId;
                this.isSuccess = false;
                this.init();
            },
            init: function () {
                if (!this.zipCode && (!this.schedule || !this.schedule.zipCode)) {
                    // This is the flow where we don't have a zip code at the beginning
                    this.disableZipCode = false;

                    this.schedule = {
                        donor: {
                            firstName: '',
                            lastName: '',
                        },
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        emailAddress: '',
                        phoneNumber: ''
                    };

                    if (this.$route.query.xE)
                        this.schedule.emailAddress = this.$route.query.xE;

                    if (this.$route.query.emailAddress)
                        this.schedule.emailAddress = this.$route.query.emailAddress;

                    if (this.$route.query.xF)
                        this.schedule.donor.firstName = this.$route.query.xF;

                    if (this.$route.query.firstName)
                        this.schedule.donor.firstName = this.$route.query.firstName;
                } else {
                    // This is the normal flow, where the zip code is disabled at the beginning and the user can't change it

                    // Store the value of this so we know if we're calling init within this flow
                    let wasZipCodeDisabled = this.disableZipCode;
                    let tempSchedule = this.schedule;
                    this.disableZipCode = true;

                    if (!wasZipCodeDisabled && this.schedule.zipCode)
                        this.zipCode = this.schedule.zipCode;

                    axios
                        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/init', {
                            zipCode: this.zipCode,
                            identityToken: this.identityToken,
                            scheduleId: this.scheduleId
                        })
                        .then(response => {
                            if (!response.data.isServiced) {
                                this.isNotServiced = true;
                                this.schedule = null;
                                this.publicAlerts = null;
                                this.publicNotes = null;
                                this.showLogin = false;
                                return;
                            }

                            this.$root.organizationId = response.data.organizationId;

                            if (response.data.organizationId == 10007 && window.location.hostname != 'pickupplease.org' && window.location.hostname != 'veteransvoice.org' && window.location.hostname != 'vva.org') {
                            // This is not on the PUP site (hostname not www.pickupplease.org) or org's own site and this is not VVA (organization ID 10001).So we'll display a message redirecting the user to PUP...
                                this.isNotServiced = true;
                                this.showPupRedirect = true;
                                this.schedule = null;
                                this.publicAlerts = null;
                                this.publicNotes = null;
                                this.showLogin = false;
                                return;
                            }

                            if (response.data.organizationId == 10006 && window.location.hostname != 'pickupplease.org') {
                                // This is not on the PUP site (hostname not www.pickupplease.org) and this is not VVA (organization ID 10001). So we'll display a message redirecting the user to PUP...
                                this.isNotServiced = true;
                                this.showPupRedirect = true;
                                this.schedule = null;
                                this.publicAlerts = null;
                                this.publicNotes = null;
                                this.showLogin = false;
                                return;
                            }

                            if (response.data.organizationId == 10008 && window.location.hostname != 'pickupplease.org') {
                                // This is not on the PUP site (hostname not www.pickupplease.org) and this is not VVA (organization ID 10001). So we'll display a message redirecting the user to PUP...
                                this.isNotServiced = true;
                                this.showPupRedirect = true;
                                this.schedule = null;
                                this.publicAlerts = null;
                                this.publicNotes = null;
                                this.showLogin = false;
                                return;
                            }
                            if (response.data.organizationId == 10009 && window.location.hostname != 'pickupplease.org') {
                                // This is not on the PUP site (hostname not www.pickupplease.org) and this is not VVA (organization ID 10001). So we'll display a message redirecting the user to PUP...
                                this.isNotServiced = true;
                                this.showPupRedirect = true;
                                this.schedule = null;
                                this.publicAlerts = null;
                                this.publicNotes = null;
                                this.showLogin = false;
                                return;
                            }

                            this.isNotServiced = false;
                            this.schedule = response.data.schedule;
                            this.schedule.donor = this.schedule.donor || {};
                            this.hasAccount = response.data.hasAccount;

                            this.schedule.channelId = this.channelId;

                            this.itemTypes = response.data.itemTypes;
                            this.locationTypes = response.data.locationTypes;
                            this.sizeTypes = response.data.sizeTypes;
                            this.zipCodeAvailabilities = response.data.zipCodeAvailabilities;

                            this.publicAlerts = response.data.publicAlerts;
                            this.publicNotes = response.data.publicNotes;

                            if (response.data.schedule.type != 'UpsLabel')
                                this.schedule.gateCodeRequired = null;

                            if (!wasZipCodeDisabled) {
                                this.schedule.donor.firstName = tempSchedule.donor.firstName;
                                this.schedule.donor.lastName = tempSchedule.donor.lastName;
                                this.schedule.addressLine1 = tempSchedule.addressLine1;
                                this.schedule.addressLine2 = tempSchedule.addressLine2;
                                this.schedule.city = tempSchedule.city;
                                this.schedule.state = tempSchedule.state;
                                this.schedule.emailAddress = tempSchedule.emailAddress;
                                this.schedule.phoneNumber = tempSchedule.phoneNumber;

                                window.setTimeout(() => {
                                    this.tabIndex = 1;
                                }, 1);
                            } else {
                                this.tabIndex = 0;
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            },
            onLogout: function (e) {
                localStorage.removeItem('identityToken');
                this.identityToken = null;
                this.init();
            },
            onSubmitLogin: function (e) {
                e.preventDefault();

                if (!this.$refs.loginControl.isValid()) {
                    this.isLoginValid = false;
                    return;
                }

                this.isLoginValid = true;

                axios
                    .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/login', this.loginData)
                    .then(response => {
                        if (response.data.exception != null) {
                            console.log(response.data.exception);
                            return;
                        }

                        this.identityToken = response.data.identityToken;
                        this.isAuthenticated = true;
                        localStorage.setItem('identityToken', this.identityToken);

                        this.showLogin = false;
                        this.init();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            onSubmitYourInfo: function (e) {
                e.preventDefault();

                if (!this.$refs.yourInfoControl.isValid()) {
                    this.isYourInfoValid = false;
                } else {
                    this.isYourInfoValid = true;

                    if (this.schedule.type == 'UpsLabel') {
                        axios.post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/verifyPhoneNumber', {
                            phoneNumber: this.schedule.phoneNumber,
                            operatorId: this.schedule.operatorId
                        })
                            .then(response => {
                                this.phoneVerificationCodeHash = response.data.codeHash;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                    // This is the normal flow, where the zip code is disabled at the beginning and the user can't change it
                    if (this.disableZipCode) {
                        window.setTimeout(() => {
                            this.tabIndex = 1;
                        }, 1);
                    } else {
                        this.init();
                    }
                }
            },
            onSubmitDonatedItems: function (e) {
                e.preventDefault();

                if (!this.$refs.donatedItemsControl.isValid()) {
                    this.isDonatedItemsValid = false;
                } else {
                    this.isDonatedItemsValid = true;

                    window.setTimeout(() => {
                        this.tabIndex = 2;
                    }, 1);
                }
            },
            onSubmitUpsItems: function (e) {
                e.preventDefault();

                if (!this.$refs.upsItemsControl.isValid())
                    return;

                this.upsertSchedule();
            },
            onUpdateAddress: function (e) {
                e.preventDefault();

                if (!this.$refs.updateAddressControl.isValid())
                    return;

                this.pushNavigateActionToDataLayer('Update Address');

                this.upsertSchedule();
            },
            onSubmitSchedule: function (e) {
                e.preventDefault();

                if (this.$refs.scheduleControl.isValid() && this.$refs.yourInfoControl.isValid() && this.$refs.donatedItemsControl.isValid()) {
                    this.scheduleProgress = true;
                }

                if (!this.$refs.scheduleControl.isValid()) {
                    this.isScheduleValid = false;
                    return;
                }

                if (!this.$refs.yourInfoControl.isValid()) {
                    this.tabIndex = 0;
                    return;
                } else if (!this.$refs.donatedItemsControl.isValid()) {
                    this.tabIndex = 1;
                    return;
                }

                this.upsertSchedule();
            },
            upsertSchedule: function () {
                axios
                    .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/upsertSchedule', {
                        identityToken: this.identityToken,
                        schedule: this.schedule,
                        password: this.schedule.password,
                        phoneVerificationCode: this.phoneVerificationCode,
                        phoneVerificationCodeHash: this.phoneVerificationCodeHash
                    })
                    .then(response => {
                        this.scheduleProgress = false;

                        this.showUpsertErrorMessage = false;
                        this.upsertErrorMessage = null;

                        this.isInvalidAddress = false;
                        this.invalidAddressDescription = null;

                        if (response.exception != null) {

                            if (response.data.invalidAddressDescription) {
                                this.tabIndex = 0;
                                this.isInvalidAddress = true;
                                this.invalidAddressDescription = response.data.invalidAddressDescription;

                                window.scrollTo(0, 0);
                            } else {
                                this.showUpsertErrorMessage = true;
                                this.upsertErrorMessage = response.data.exception;

                                window.scrollTo(0, 0);
                            }
                            console.log(response.data.exception);
                            return;
                        }

                        this.isSuccess = true;
                        this.schedule = response.data.schedule;
                        this.identityToken = response.data.identityToken;
                        this.scheduleId = response.data.schedule.id;

                        this.isGeocodingIssue = response.data.schedule.geocodingStatus == 'NeedsConfirmation';

                        console.log("schedule type:" + this.schedule.type);
                        if (this.schedule.type == 'UpsLabel') {
                            this.labels = [];

                            let idx = 0;
                            let tempLabels = [];

                            console.log("tns:" + this.schedule.trackingNumbers.length);
                            console.log("id:" + this.scheduleId);
                            for (let i = 0; i < this.schedule.trackingNumbers.length; ++i) {
                                console.log("tracking numbers loop running");
                                axios.post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/viewUpsLabel', {
                                    scheduleId: this.scheduleId,
                                    identityToken: this.identityToken,
                                    trackingNumber: this.schedule.trackingNumbers[i]
                                }, {
                                    responseType: 'blob'
                                })
                                    .then(response => {
                                        let file = new File([response.data], "UpsLabel.gif", {
                                            type: 'image/gif'
                                        });
                                        let url = URL.createObjectURL(file);
                                        tempLabels.push(url);

                                        ++idx;
                                        if (idx == this.schedule.trackingNumbers.length)
                                            this.labels = tempLabels;
                                    })
                            }
                        } else {
                            this.labels = null;
                        }

                        if (this.zipCodeAvailabilities && this.zipCodeAvailabilities[0] && this.zipCodeAvailabilities[0].date)
                            this.pushScheduleToDataLayer(this.schedule, 'schedule-pickup', this.zipCodeAvailabilities[0].date);
                        else
                            this.pushScheduleToDataLayer(this.schedule, 'schedule-pickup', null);

                        window.scrollTo(0, 0);
                    })
                    .catch(error => {
                        this.scheduleProgress = false;
                        console.log(error);
                    });
            },
            printLabel: function (index) {
                let url = this.labels[index];
                console.log(url);
                let w = window.open(url);
                w.print();

                this.pushNavigateActionToDataLayer('Print Label');
            }
        },
        watch: {
            $route() {
                this.initWithParams();
            },
            tabIndex(newValue, oldValue) {
                let name = 'Unknown';

                if (newValue == 0)
                    name = 'Your Info';
                else if (newValue == 1)
                    name = 'Donated Items';
                else if (newValue == 2)
                    name = 'Schedule';

                this.pushNavigateActionToDataLayer(name);
            }
        },
        mounted() {
            this.initWithParams();
        }
    }
</script>