<template>
  <div>

    <b-form-row>
      <b-col>
        <check-box-list-control v-model="schedule.itemTypes" id-field="itemTypeId" :options="itemTypesSelectOptions" name="itemTypeIds" label="What items do you think you will be donating?" required />
      </b-col>
    </b-form-row>

    <p>We are only accepting clothing, shoes, accessories, jewelry, purses, belts, and other accessories. Please no books, household items, furniture, or electronics.</p>

    <b-form-row>
      <b-col>
        <radio-button-list-control v-model="schedule.numberOfBoxes" :options="numberOfBoxesSelectOptions" name="numberOfBoxes" label="About how many boxes or bags will you be donating?" required />
      </b-col>
    </b-form-row>

    <p>Each box requires its own shipping label. Each box must be large enough to hold more than 15 lbs.</p>

    <p>By continuing, you agree to the following:</p>
    <ul>
      <li>I will only send donations that are clean, usable, and "like new".<br />
        <i>Friendly Tip: If you doubt the quality of your items, simply do not include it.</i></li>
      <li>I will NOT send items that are not on the list of acceptable items.<br />
        <i>Friendly Tip: Please only send in clothing, shoes, and accessories.</i></li>
      <li>I will make sure the box weighs at least 15 lbs.<br />
        <i>Friendly Tip: Leave no space. Generally 20+ items of clothing will weigh over 15 pounds.</i></li>
    </ul>

    <b-form-row v-if="!hasAccount">
      <b-col>
        <text-control v-model="schedule.password" type="password" :custom-validation="validatePassword" label="If you would like to create an account, please create a password." description="Creating an account is optional. Once you have created an account, you will not need to use SMS text message verifications in the future." placeholder="New Password" />
        <text-control v-if="schedule.password" v-model="schedule.confirmPassword" type="password" :custom-validation="validateConfirmPassword" label="Please confirm your new password" placeholder="Confirm Password" />
      </b-col>
    </b-form-row>

  </div>
</template>

<script>
import FormMixin from '../mixins/FormMixin.vue';

export default {
  name: 'UpsItemsControl',
  mixins: [FormMixin],
  props: {
    hasAccount: null,
    schedule: null,
    itemTypes: null
  },
  data() {
    return {
      numberOfBoxesSelectOptions: [
        {
          text: '1',
          value: 1
        },
        {
          text: '2',
          value: 2
        },
        {
          text: '3',
          value: 3
        },
        {
          text: '4',
          value: 4
        },
        {
          text: '5',
          value: 5
        }
      ]
    };
  },
  computed: {
    itemTypesSelectOptions: function () {
      if (!this.itemTypes)
        return null;

      return this.itemTypes.filter(x => x.id == 101 || x.id == 139 || x.id == 102).map(x => ({ text: x.name, value: x.id }));
    },
  },
  methods: {
    isValid: function () {
      return this.isFormValid();
    },
    validatePassword: function (newValue) {
      if (newValue && newValue.length < 10)
        return 'Please choose a password that\'s at least 10 characters.';
      
      return true;
    },
    validateConfirmPassword: function (newValue) {
      if (!newValue && this.schedule.password || newValue != this.schedule.password)
        return 'Please make sure your password confirmation matches your new password.';
      
      return true;
    }
  },
  mounted () {
  }  
}
</script>
