<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="12" md="2" lg="3">
        </b-col>
        <b-col md="8" lg="6" v-if="schedule">
          <h2>Cancel Pickup</h2>
          <hr />

          <div v-if="!isSuccess && schedule && schedule.cancellationReason != 'Active'">
            <p>Your pickup on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span> was cancelled. To see other scheduled pickups, or to schedule a new one, please visit your <b-link :to="getDashboardUrl()">donor dashboard</b-link>.</p>
          </div>

          <div v-if="isSuccess">
            <p>Your pickup at <span class="font-weight-bold">{{getFormattedAddress(schedule)}}</span> on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span> has been cancelled.</p>

            <div class="text-center">
              <b-button :to="getDashboardUrl()" class="w-100 p-2 my-2">Go to My Donor Dashboard</b-button>
            </div>
          </div>

          <div v-if="!isSuccess && schedule && schedule.cancellationReason == 'Active'">
            <p>Are you sure you want to cancel your pickup at <span class="font-weight-bold">{{getFormattedAddress(schedule)}}</span> on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span>?</p>
            <b-form @submit="onSubmitCancel">
              <div class="text-center">
                <b-button type="submit" variant="danger" class="w-100 p-2 my-2">Yes, Cancel My Pickup</b-button>
              </div>
            </b-form>
            <div class="text-center">
              <b-button :to="getDashboardUrl()" class="w-100 p-2 my-2">No, Do Not Cancel My Pickup</b-button>
            </div>
            <div class="text-center">
              <b-button :to="getRescheduleUrl(schedule)" class="w-100 p-2 my-2">Reschedule My Pickup</b-button>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="2" lg="3">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Constants from '../../store/constants'
import axios from 'axios'
import FormattingMixin from '../mixins/FormattingMixin.vue';
import DataLayerMixin from '../mixins/DataLayerMixin.vue';

export default {
  name: 'CancelPage',
  mixins: [
    FormattingMixin,
    DataLayerMixin
  ],
  props: {
  },
  data() {
    return {
      identityToken: null,
      scheduleId: null,
      schedule: null,

      isSuccess: false,

      channelId: 10001,
    };
  },
  computed: {
  },
  methods: {
    initWithParams: function () {
      this.identityToken = null;
      this.isAuthenticated = false;

      if (localStorage.getItem('identityToken')) {
        this.identityToken = localStorage.getItem('identityToken');
        this.isAuthenticated = true;
      }

      // An identity token in the URL supercedes a stored identity token
      if (this.$route.query.identityToken)
        this.identityToken = this.$route.query.identityToken;

      this.scheduleId = this.$route.query.scheduleId;
      this.isSuccess = false;
      this.init();
    },
    init: function () {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/init', {
          zipCode: this.zipCode,
          identityToken: this.identityToken,
          channelId: this.channelId,
          scheduleId: this.scheduleId
        })
        .then(response => {
          this.schedule = response.data.schedule;
        })
        .catch(error => {
          console.log(error);
        });
    },
    onSubmitCancel: function () {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/cancelSchedule', {
          identityToken: this.identityToken,
          scheduleId: this.scheduleId,
        })
        .then(response => {
          this.isSuccess = true;
          this.schedule = response.data.schedule;

          this.pushScheduleToDataLayer(this.schedule, 'cancel-pickup', null);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  watch: {
    $route () {
      this.initWithParams();
    }
  },
  mounted () {
    this.initWithParams();
  }  
}
</script>
