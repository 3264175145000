import { render, staticRenderFns } from "./TextControl.vue?vue&type=template&id=27f1a6e0&"
import script from "./TextControl.vue?vue&type=script&lang=js&"
export * from "./TextControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../PublicApp/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports