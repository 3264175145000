<template>
    <div>
        <b-container>
            <b-row>
                <b-col sm="12" md="2" lg="3">
                </b-col>
                <b-col md="8" lg="6" v-if="schedule">
                    <h2>Confirm Pickup</h2>
                    <hr />

                    <div v-if="isSuccess">
                        <p>Your pickup at <span class="font-weight-bold">{{getFormattedAddress(schedule)}}</span> on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span> has been confirmed.</p>
                        <div class="text-center">
                            <b-button :to="getDashboardUrl()" class="w-100 p-2 my-2">Go to My Donor Dashboard</b-button>
                        </div>
                    </div>

                    <div v-if="!isSuccess">
                        <p>Please confirm your pickup at <span class="font-weight-bold">{{getFormattedAddress(schedule)}}</span> on <span class="font-weight-bold">{{getFormattedDate(schedule.pickupDate)}}</span>.</p>
                        <b-form @submit="onSubmitCancel">
                            <div class="text-center">
                                <b-button type="submit" variant="success" class="w-100 p-2 my-2">Confirm My Pickup</b-button>
                            </div>
                        </b-form><hr />
                        <p align="center">
                            <table align="center">
                                <tr>
                                    <td colspan="2" align="center">
                                        <br />
                                        Additional reminder: No large furniture, large appliances or TV�s accepted. We only accept items that are small and light enough for one person to easily lift and carry. Acceptable donation items include:
                                        <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <br />
                                        Clothing & Shoes<br />
                                        Household Items<br />
                                        Small Appliances<br />
                                        Kitchenware<br />
                                    </td>
                                    <td>
                                        <br />
                                        Electronics<br />
                                        Sports Equipment<br />
                                        Books & Toys<br />
                                        Small Furnishings<br />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" align="center">
                                        <br />
                                        Thank you for donating to help support our veterans!
                                        If you have any questions please call Pickup Please at (800) 775-8387.<br />
                                    </td>
                                </tr>
                            </table>
                        </p>
                        <hr />
                        <p>Alternatively, you can choose below to reschedule or cancel this pickup.</p>
                        <div class="text-center">
                            <b-button :to="getRescheduleUrl(schedule)" class="p-2 my-2">Reschedule My Pickup</b-button>
                        </div>
                        <div class="text-center">
                            <b-button :to="getCancelUrl(schedule)" class="p-2 my-2">Cancel Pickup</b-button>
                        </div>
                    </div>
                </b-col>
                <b-col sm="12" md="2" lg="3">
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import Constants from '../../store/constants'
    import axios from 'axios'
    import FormattingMixin from '../mixins/FormattingMixin.vue';

    export default {
        name: 'ConfirmPage',
        mixins: [FormattingMixin],
        props: {
        },
        data() {
            return {
                identityToken: null,
                scheduleId: null,
                schedule: null,
                isEmail: false,
                isSMS: false,

                isSuccess: false,

                channelId: 10001,
            };
        },
        computed: {
        },
        methods: {
            initWithParams: function () {
                this.identityToken = null;
                this.isAuthenticated = false;
                this.isSMS = false;
                this.isEmail = false;

                if (localStorage.getItem('identityToken')) {
                    this.identityToken = localStorage.getItem('identityToken');
                    this.isAuthenticated = true;
                }

                // An identity token in the URL supercedes a stored identity token
                if (this.$route.query.identityToken)
                    this.identityToken = this.$route.query.identityToken;

                // console.log(this.$route.query);

                if (this.$route.query.source == 'email')
                    this.isEmail = true;
                if (this.$route.query.source == 'sms')
                    this.isSMS = true;

                this.scheduleId = this.$route.query.scheduleId;
                this.isSuccess = false;
                this.init();
            },
            init: function () {
                axios
                    .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/init', {
                        zipCode: this.zipCode,
                        identityToken: this.identityToken,
                        channelId: this.channelId,
                        scheduleId: this.scheduleId
                    })
                    .then(response => {
                        this.schedule = response.data.schedule;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            onSubmitCancel: function () {
                axios
                    .post(process.env.VUE_APP_API_BASE_URL + '/publicFormSupport/confirmSchedule', {
                        identityToken: this.identityToken,
                        scheduleId: this.scheduleId,
                        isEmail: this.isEmail,
                        isSMS: this.isSMS
                    })
                    .then(response => {
                        this.isSuccess = true;
                        this.schedule = response.data.schedule;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        watch: {
            $route() {
                this.initWithParams();
            }
        },
        mounted() {
            this.initWithParams();
        }
    }
</script>
