<template>
    <div>
        <router-view></router-view>
        <div style="text-align:center;">
            <div id="lImg" v-if="logoFilename" >
                <img :src="require('./assets/' + logoFilename)" />
            </div>
            <div id="pDev" v-if="isDev">
                <hr />
                <div>
                    Schedule - No user:
                    <b-link to="/schedule?zipCode=91711&channelId=10006">New Schedule (91711, Channel 10006)</b-link> |
                    <b-link to="/schedule?zipCode=91711">New Schedule (91711, No Channel)</b-link> |
                    <b-link to="/schedule?zipCode=10022&channelId=10013">New Schedule (10022, Channel 10013)</b-link> |
                    <b-link to="/schedule?zipCode=53211">New Schedule (53211)</b-link> |
                    <b-link to="/schedule?xZ=91711&xA=10006">New Schedule (Old Params, 91711, Channel 10006)</b-link> |
                    <b-link to="/schedule?xA=10004&xE=test@test.com&xF=Chris">New Schedule (Old Params, Channel 10004, Chris, test@test.com)</b-link>
                </div>
                <div>
                    Schedule - User:
                    <b-link to="/schedule?zipCode=91750&identityToken=MTAwMjJ8ZnVJOVVQVk1QVjlzKytRcUNvRE4vd3hQLzdQY1ZuWVdzRFg1c2RwRGpKcz0=">New Schedule (User 10022)</b-link> |
                    <b-link to="/schedule?zipCode=91750&identityToken=MTAwMjJ8ZnVJOVVQVk1QVjlzKytRcUNvRE4vd3hQLzdQY1ZuWVdzRFg1c2RwRGpKcz0=&channelId=10006">New Schedule (User 10022, Channel 10006)</b-link> |
                    <b-link to="/schedule?zipCode=02061&identityToken=MzIxMzQ3N3x6MlpMQXFYMyt2RkJpaTQ3L0FzMmhRTGlmdFhlaHVLbmY4bDNubXl1L25vPQ==">New Schedule (User 3213477)</b-link> |
                    <b-link to="/schedule?zipCode=02061&identityToken=MzIxMzQ3N3x6MlpMQXFYMyt2RkJpaTQ3L0FzMmhRTGlmdFhlaHVLbmY4bDNubXl1L25vPQ==&scheduleId=18390808">Existing Schedule (User 3213477, Schedule 18390808)</b-link>
                </div>
                <hr />
                <div>
                    Dashboard - No user:
                    <b-link to="/">Dashboard</b-link>
                </div>
                <div>
                    Dashboard - User:
                    <b-link to="/?identityToken=MTAwMjJ8ZnVJOVVQVk1QVjlzKytRcUNvRE4vd3hQLzdQY1ZuWVdzRFg1c2RwRGpKcz0=">10022</b-link> |
                    <b-link to="/?identityToken=MzIxMzQ3N3x6MlpMQXFYMyt2RkJpaTQ3L0FzMmhRTGlmdFhlaHVLbmY4bDNubXl1L25vPQ==">3213477</b-link> |
                    <b-link to="/?identityToken=MTEyNDA5MTB8UGd6cVdiTy9oU2J2RU0vQ1lZOURqYUZmYnFLNCtpOURINEFIbFNTZmRlND0%3D">11240910</b-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ZipcodeAPI } from './utils/action'

    export default {
        name: 'App',
        components: {
        },
        computed: {
            isDev: function () {
                return process.env.NODE_ENV == 'development';
            },
            logoFilename: function () {
                // You can access the organizationId (this.$root.organizationId) and the channelId (this.$root.channelId) which indicates the site that you're on (PUP, SAP, HD, etc.)
                if (this.$root.organizationId && this.$root.organizationId == 10001)
                    return 'vvalogo.png';
                else if (this.$root.organizationId && this.$root.organizationId == 10007 && (this.$root.channelId == 10102 || this.$root.channelId == 10001))
                    return 'vetvoice.png';
                else if (this.$root.organizationId && this.$root.organizationId == 10008 && (this.$root.channelId == 10104 || this.$root.channelId == 10001))
                    return 'SOTX.png';
                else if (this.$root.organizationId && this.$root.organizationId == 10006 && (this.$root.channelId == 10105 || this.$root.channelId == 10001))
                    return 'tctx.png';
                else if (this.$root.organizationId && this.$root.organizationId == 10009 && (this.$root.channelId == 10106 || this.$root.channelId == 10001))
                    return 'clean.png';
                else
                    return null;
            },
        },
        beforeCreate () {
            ZipcodeAPI.get();
        }
    }
</script>

<!-- https://stackoverflow.com/questions/48032006/how-do-i-use-deep-or-or-v-deep-in-vue-js -->
<style lang="scss" scoped>
::v-deep {
  @import './custom.scss';
}
</style>