
const token = {
  get: (type) => {
    if(!process.client) return;
    const item = localStorage.getItem(type || 'user:token');
    return item;
  },
  remove: (type) => {
    if(!process.client) return;
    const item = localStorage.removeItem(type || 'user:token');
    return item;
  },
  set: (type, newToken) => {
    if(!process.client) return;
    const item = localStorage.setItem(type || 'user:token', newToken);
    return item;
  },
};

export default token;
