<script>
export default {
  name: "FormMixin",
  props: [
  ],
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    isFormValid: function (formName) {
      if (!formName)
        formName = 'Default';

      // We run through each item, rather than just calling .every, to ensure each item gets checked and can update its styling accordingly
      let isValid = true;
      this.$children.forEach(childComponent => {
        if (childComponent.formName && childComponent.formName != formName)
          return;
        if (typeof(childComponent.isValid) === 'undefined')
          return;
        if (!childComponent.isValid) {
          let label = '(Unknown)';
          if (childComponent && childComponent.label)
            label = childComponent.label;
          
          console.log('Validation failed: ' + label);
          isValid = false;
        }
      });

      return isValid;

      //return this.$children.every(childComponent => { return (childComponent.formName && childComponent.formName != formName) || typeof(childComponent.isValid) === 'undefined' || childComponent.isValid; });
    },
  },
  mounted () {
  }
};
</script>