<script>
import { DateTime } from 'luxon';

export default {
  name: "FormattingMixin",
  props: [
  ],
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    getFormattedDate: function (d) {
      let dt = DateTime.fromISO(d);
      return dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },
    getFormattedDateTime: function (d) {
      let dt = DateTime.fromISO(d);
      return dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) + ' ' + dt.toFormat('h:mm a ZZZZ');
    },
    getCamelSpacedText: function (value) {
      return value.replace(/([A-Z])/g, ' $1').trim()
    },
    getDashboardUrl: function () {
      return { path: '/', query: {
        identityToken: this.identityToken
      }};
    },
    getChangeUrl: function (schedule) {
      return { path: 'schedule', query: {
        zipCode: schedule.zipCode,
        identityToken: this.identityToken,
        scheduleId: schedule.id
      } }
    },
    getRescheduleUrl: function (schedule) {
      return { path: 'reschedule', query: {
        zipCode: schedule.zipCode,
        identityToken: this.identityToken,
        scheduleId: schedule.id
      } }
    },
    getCancelUrl: function (schedule) {
      return { path: 'cancel', query: {
        identityToken: this.identityToken,
        scheduleId: schedule.id
      } }
    },
    getFormattedAddress: function (schedule) {
      let parts = [];
      
      parts.push(schedule.addressLine1);

      if (schedule.addressLine2 && schedule.addressLine2 != '') {
        parts.push(', ');
        parts.push(schedule.addressLine2);
      }

      parts.push(', ');
      parts.push(schedule.city);
      parts.push(', ')
      parts.push(schedule.state);
      parts.push(' ');
      parts.push(schedule.zipCode);

      return parts.join('');
    },
  },
  mounted () {
  }
};
</script>