<template>
  <div>
    <b-form-row>
      <b-col>
        <text-control v-model="loginData.emailAddress" placeholder="Email Address" required type="email" />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <text-control v-model="loginData.password" placeholder="Password" required type="password" />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import FormMixin from '../mixins/FormMixin.vue';

export default {
  name: 'LoginControl',
  mixins: [FormMixin],
  props: {
    loginData: null
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    isValid: function () {
      return this.isFormValid();
    }
  },
  mounted () {
  }  
}
</script>
